.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.input-container {
  margin-bottom: 0px; /* Adjust the margin to control the space */
}

/* Style the contact section container */
.contact-section {
  margin-top: 0px; /* Adjust the margin to control the space */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent; /* Remove boundary color */
  padding: 0px;
}

/* Style the "Inquiry via" text */
.inquiry-text {
  margin-right: 10px; /* Add some spacing between text and buttons */
  font-size: 14px;
  color: black; 
}

/* Style the container for LinkedIn and Email buttons */
.contact-buttons {
  display: flex;
  gap: 10px; /* Adjust the gap between buttons */
  align-items: center;
}

/* Style the LinkedIn button */
.linkedin-button {
  display: inline-block;
  background-color:white; /* Gray color for the button */
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none; /* Remove underlines for links */
  color: #fff; /* White text color */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

.linkedin-button:hover {
  background-color: #555; /* Darker gray color on hover */
}

/* Style the Email button */
.email-button {
  display: inline-block;
  background-color: #888; /* Gray color for the button */
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none; /* Remove underlines for links */
  color: #fff; /* White text color */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

.email-button:hover {
  background-color: #555; /* Darker gray color on hover */
}

/* Style the nested anchor within the Email button */
.email-button a {
  color: inherit;
  text-decoration: inherit;
}

.label-text {
  font-size: 14px; /* Adjust the font size to your preference */
}
